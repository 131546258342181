.services-int{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 30rem;
    margin-bottom: 8rem;
    margin-top: -4rem;
    margin-bottom: 8rem;
}
.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}
.awesome>:nth-child(1)>h1{
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
}
.awesome>:nth-child(2)>h1{
    font-size: 3rem;
    font-weight: bold;
    color: var(--orange);
    margin-top: -50px;
}
.awesome>:nth-child(3){
    color: var(--gray);
    margin-top: -40px;
}
.s-button{
    margin-top: 1rem;
    width: 8rem;
    height: 2rem;
}


.cards{
position: relative;
}
.cards>*{
    position: absolute;
}


.s-blur2{
    left: 14rem;
    top: 8rem;
}
.s-blur1{
    top: 13rem;
    left: -18rem;
}

@media screen and (max-width:980px) {
    .services-int{
        margin-left: -100px;
        margin-top: -200px;
    }
    .cards{
        position: relative;
        left: -500px;
        top: 300px;
    }
}

@media screen and (max-width:768px) {
    .services-int{
        margin-left: -100px;
        margin-top: -200px;
    }
    .cards{
        position: relative;
        left: -400px;
        top: 300px;
    }
}




@media screen and (max-width: 480px) {
    .awesome>:nth-child(1)>h1{
        width: 90vw;
    }
    .services-int{
        margin-left: -50px;
        margin-top: 400px;
    }
    .awesome>h1{
        width: 100vw;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 17rem;
        margin-left: 50px;
        margin-top: 100px;
    }
    .cards> *{
        position: static;
    }
}

@media screen and (max-width:380px) {
    .cards{
        margin-top: 150px;
        margin-left: 100px;
    }
}