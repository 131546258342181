

.t-wrapper{
    padding:  0 3rem 0 3rem;
    height: 33rem;
    margin-top: 7rem;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}
.t-heading{
    align-self: start;
    display: flex;
    flex-direction: column;
}
.t-heading>span:nth-of-type(1){
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}
.t-heading>span:nth-of-type(2){
    color: var(--gray);
    font-size: 14px;
    text-align: center;
}

.t-blur1{
    left: 38rem;
    top: 16rem;
}
.t-blur2{
    top: 9rem;
    left: 0;
}
.testimonial{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    gap: 1rem;
    width: 40rem;
    height: 12rem;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}
.testimonial>div{
    width: 27rem;
    height: 10rem;
}
.testimonial>div>img{
    width: 100%;
    height: 100%;
}
.testimonial>span{
    color: var(--gray);
}
.para{
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    gap: 0.5rem;
}
.para>span:nth-of-type(1){
    font-size: 2rem;
}
.para>button{
    width: 6rem;
    background-color: var(--orange);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin-top: 20px;
}


/* slider */
.t-wrapper .swiper {
    width: 80vw;
    height: 70%;
}

.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active{
    background: var(--orange);
}





@media screen and (max-width:980px) {
    .testimonial{
        display: flex;
        flex-direction: column;
        height: 20rem;
        width: 33rem;
    }
    .t-wrapper .swiper{
        height: 29rem;
        width: 100vw;
        margin-left: -130px;
    }
    .testimonial>div{
        width: 70vw;
        height: 10rem;
    }
    .testimonial>div>img{
        width: 100%;
        height: 100%;
    }
}


@media screen and (max-width:480px) {
    .t-wrapper{
        margin-top: 1200px;
        margin-bottom: 130px;
    }
    .t-wrapper .swiper{
        width: 100vw;
        margin-left: -80px;
        height: 37rem;
    }
    .testimonial{
        height: 28rem;
        margin-top: -50px;
    }
    .para{
        position: relative;
        top: -150px;
    }
    .para>span:nth-of-type(1){
        font-size: 1.5rem;
    }
}